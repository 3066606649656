<template>
  <WeContainer card="">
    <!-- Top Card -->
    <WeCard class="mb-3 sticky-top shadow-sm">
      <div class="row align-items-center">
        <div class="col-12 col-md text-center text-md-left">
          <h5 class="mb-md-0">
            <i class="fas fa-sync"></i> Toplu Kategori SEO Güncelle
          </h5>
        </div>
        <div class="col-12 col-md-auto text-center">
          <!-- Submit -->
          <WeSubmitButton
            parent-class="text-center"
            v-bind:submit-status="submit"
            v-bind:update="$route.params.id"
            v-bind:permission="checkPermission"
            v-on:submit="submitForm"
          />
          <!-- ./Submit -->
        </div>
      </div>
    </WeCard>
    <!-- ./Top Card -->

    <!-- Filter -->
    <WeCard class="mb-3">
      <h5 class="custom-card-title">
        <i class="fas fa-filter"></i>
        <span>Filtreleme</span>
      </h5>
      <hr />
      <div class="col-12 col-lg-6">
        <!-- Category -->
        <div class="d-block mb-2">
          <WeRowInput
            form="searchSelect"
            size="sm"
            name="category"
            label="Kategori"
            placeholder="Kategori"
            v-model="filter.category"
            v-bind:clearable="true"
          />
        </div>
        <!-- Category -->

        <!-- We Seo Filter -->
        <div class="d-block">
          <WeSeoFilter v-model="filter" />
        </div>
        <!-- We Seo Filter -->

        <div class="mt-3">
          <span
            v-if="!filter_submit"
            class="btn btn-sm btn-primary"
            v-on:click="getCategoryList"
            >Filtrele</span
          >
          <span v-else class="btn btn-sm btn-primary"
            ><i class="fas fa-spinner fa-spin"></i
          ></span>
          <span class="btn btn-danger btn-sm ml-2" v-on:click="onClear"
            >Temizle</span
          >
        </div>
      </div>
    </WeCard>
    <!-- ./Filter -->

    <!-- Update SEO -->
    <WeCard class="mb-3">
      <h5 class="custom-card-title">
        <i class="fas fa-sync"></i>
        <span>Güncelleme</span>
      </h5>
      <hr />
      <WeMetaData
        class="ck-editor-seo"
        v-model="meta"
        v-bind:show-robots="false"
        v-bind:use-editor="true"
        v-bind:show-canonical="false"
        v-bind:mentions="getMentions"
        v-bind:show-limits="false"
      />
    </WeCard>
    <!-- ./Update SEO -->

    <WeCard v-if="bulkSeo.list.length" id="category-list">
      <h5 class="custom-card-title">
        <i class="fas fa-stream"></i>
        <span>Güncellenecek Kategoriler</span>
      </h5>
      <hr />
      <WeTable
        v-bind:index="false"
        v-bind:data="bulkSeo.list"
        v-bind:columns="columns"
      />
    </WeCard>
  </WeContainer>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "CategorySeo",
  data() {
    return {
      submit: false,
      filter_submit: false,
      route: "categories",
      filter: {
        category: null,
        is_active: null,
        seo_title: null,
        seo_keywords: null,
        seo_description: null,
      },
      copyFilter: {},
      meta: {
        title:
          "<span class='mention' data-mention='@CategoryMetaTitle'>@CategoryMetaTitle</span>",
        keywords:
          "<span class='mention' data-mention='@CategoryMetaKeywords'>@CategoryMetaKeywords</span>",
        description:
          "<span class='mention' data-mention='@CategoryMetaDescription'>@CategoryMetaDescription</span>",
      },
      mentions: [
        {
          id: "@CategoryName",
          name: "Kategori Adı",
        },
        {
          id: "@CategoryMetaTitle",
          name: "Kategori Meta Başlık",
        },
        {
          id: "@CategoryMetaKeywords",
          name: "Kategori Anahtar Kelimeleri",
        },
        {
          id: "@CategoryMetaDescription",
          name: "Kategori Meta Açıklaması",
        },
      ],
      columns: [
        { name: "image", icon: "fas fa-camera", type: "image", width: "5%" },
        { name: "name", th: "Kategori Adı", type: "string" },
      ],
    };
  },
  methods: {
    ...mapActions("bulkSeo", ["load", "update", "getList"]),
    getCategoryList(filter_submit = true) {
      this.filter_submit = filter_submit;
      this.copyFilter = helper.clone(this.filter);

      this.getList({
        route: this.route,
        filter: this.copyFilter,
        onFinish: () => {
          // Show button text insted of spinner
          this.filter_submit = false;

          if (this.bulkSeo.list && this.bulkSeo.list.length) {
            // Scroll to list
            document
              .getElementById("category-list")
              .scrollIntoView({ behavior: "smooth" });
          }
        },
      });
    },
    submitForm() {
      if (!this.checkPermission) {
        permission.alert(this);
      } else {
        if (this.bulkSeo.list && this.bulkSeo.list.length) {
          this.$swal({
            title: "İşlemi Onaylıyor musunuz ?",
            html: `<b>${this.bulkSeo.list.length}</b> adet kategori güncellenecek. Bu işlem geri alınamaz.`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "İptal",
            confirmButtonText: "Güncelle",
          }).then((confirm) => {
            if (confirm.isConfirmed) {
              this.makeRequest();
            }
          });
        } else {
          this.$swal({
            title: "Geçersiz İşlem",
            text: "Lütfen güncellenecek kategorileri filtreleyin",
            icon: "error",
          });
        }
      }
    },
    makeRequest() {
      this.submit = true;
      this.update({
        route: this.route,
        filter: this.copyFilter,
        requestData: this.meta,
        onSuccess: (result) => {
          this.$swal({
            title: "İşlem Başarılı",
            text:"Güncelleme işlemi kuyruğa alındı tamamlandığında mail adreinize bildirim gönderilecektir.",
            icon: "success",
            confirmButtonText: "Tamam",
            onClose: () => this.getCategoryList(),
          });
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    onClear() {
      this.filter = {
        category: null,
        is_active: null,
        seo_title: null,
        seo_keywords: null,
        seo_description: null,
      };
      this.copyFilter = {};
      this.getCategoryList(false);
    },
  },
  computed: {
    ...mapState(["bulkSeo"]),
    checkPermission() {
      return permission.check("category", "u");
    },
    getMentions() {
      return [...this.bulkSeo.globalMentions, ...this.mentions];
    },
  },
  mounted() {
    this.load();
  },
};
</script>
